@font-face {
  font-family: "Cera Pro";
  src: url("./otf/CeraPro-Thin.otf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./otf/CeraPro-Light.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./otf/CeraPro-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: "Cera Pro";
    src: url("./otf/CeraPro-Medium.otf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Cera Pro";
    src: url("./otf/CeraPro-Bold.otf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./otf/CeraPro-Black.otf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
